<template>
  <CarSearch v-bind="dataParams" @openBuy="openBuy" @openRent="openRent" />
  <Modal @close="closeModal">
    <RentForm
      v-if="modals.showRent"
      @close="closeModal"
      :carPrice="modals.selectedCar.price"
      :carName="modals.selectedCar.title"
    />
    <BuyForm
      v-if="modals.showBuy"
      @close="closeModal"
      :carName="modals.selectedCar.title"
    />
  </Modal>
</template>

<script setup>
import { reactive } from "vue";
import CarSearch from "@/blocks/CarSearch.vue";
import Modal from "@/components/Modal.vue";
import RentForm from "@/components/forms/RentForm.vue";
import BuyForm from "@/components/forms/BuyForm.vue";

const container = document.getElementById("car_search");

const dataParams = container.dataset;

const modals = reactive({
  selectedCar: null,
  showBuy: false,
  showRent: false,
});

const openBuy = (car) => {
  modals.selectedCar = car;
  modals.showBuy = true;
};

const openRent = (car) => {
  modals.selectedCar = car;
  modals.showRent = true;
};

const closeModal = () => {
  modals.showBuy = false;
  modals.showRent = false;
  modals.showFeedback = false;
};
</script>
