import "@/style.scss";
import "@/assets/styles/contacts.scss";

import { createApp } from "vue";
import VueTheMask from "vue-the-mask";
import Contacts from "./Contacts.vue";

const about = createApp(Contacts);
about.use(VueTheMask);
about.mount("#contacts");

async function initMap() {
  // Промис `ymaps3.ready` будет зарезолвлен, когда загрузятся все компоненты основного модуля API
  await ymaps3.ready;

  const {
    YMap,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer,
    YMapMarker,
    YMapControls,
  } = ymaps3;
  const { YMapZoomControl, YMapGeolocationControl } = await ymaps3.import(
    "@yandex/ymaps3-controls@0.0.1"
  );
  const { YMapDefaultMarker } = await ymaps3.import(
    "@yandex/ymaps3-markers@0.0.1"
  );
  const { YMapOpenMapsButton } = await ymaps3.import(
    "@yandex/ymaps3-controls-extra"
  );

  // Иницилиазируем карту
  const map = new YMap(
    // Передаём ссылку на HTMLElement контейнера
    document.querySelector("#y-maps"),

    // Передаём параметры инициализации карты
    {
      location: {
        // Координаты центра карты
        center: [37.58888, 55.753058],

        // Уровень масштабирования
        zoom: 16,
        // autoFitToViewport: 'always'
      },
    }
  );

  // Добавляем слой для отображения схематической карты
  map.addChild(new YMapDefaultSchemeLayer());

  const controlsRight = new YMapControls({ position: "right" });
  controlsRight.addChild(new YMapGeolocationControl());
  controlsRight.addChild(new YMapZoomControl());
  map.addChild(controlsRight);

  const controlsBottomRight = new YMapControls({
    position: "bottom left",
    orientation: "horizontal",
  });

  controlsBottomRight.addChild(
    new YMapOpenMapsButton({
      LngLat: [37.58888, 55.753058],
    })
  );
  map.addChild(controlsBottomRight);

  map.addChild(new YMapDefaultFeaturesLayer({ zIndex: 1800 }));

  const defaultMarker = new YMapDefaultMarker({
    coordinates: [37.58888, 55.753058],
    title: "Pekinauto",
  });

  map.addChild(defaultMarker);

  const markerEl = document.querySelector("#y-maps-marker");
  const marker = new YMapMarker(
    {
      coordinates: [37.58888, 55.753058],
    },
    markerEl
  );

  map.addChild(marker);
}

initMap();
